import axios from 'src/_services/axios-configs'
import config from 'src/_services/config'
import auth from './auth'

import { getBase64 } from '@/_helpers'

const state = {
  selectedContactId: null,
  myMailboxesComponent: [],
  DBVoicemail: [],
  voicemails: [],
  invitationUuid: null,
  userImage: null,
  accountUsers: [],
  mailboxes: [],
  paymentCards: []
}

const getters = {
  filter: (state) => (value) => {
    return value ? state.voicemails.filter(item => item.unread === true) : state.voicemails
  },
  getInvitationUuid: s => s.invitationUuid,
  userImage: s => s.userImage,
  accountUsers: s => s.accountUsers,
  mailboxes: s => s.mailboxes,
  paymentCards: s => s.paymentCards
}

const mutations = {
  setSelectedContactId (state, payload) {
    state.selectedContactId = payload
  },
  setVoicemails (state) {
    state.voicemails = state.DBVoicemail
  },
  setVoicemailMailbox (state, payload) {
    const filterItems = (query) => {
      return state.DBVoicemail.filter((item) => {
        return item.source.toLowerCase().indexOf(query.toLowerCase()) > -1
      })
    }
    state.voicemails = filterItems(payload)
  },
  removeVoicemails (state, payload) {
    state.voicemails.splice(payload, 1)
  },
  setInvitationUuid (state, value) {
    state.invitationUuid = value
  },
  setUserImage (state, payload) {
    state.userImage = payload
  },
  setAccountUsers (state, payload) {
    state.accountUsers = payload
  },
  setMailboxes (state, payload) {
    state.mailboxes = payload
  },
  setPaymentCards (state, payload) {
    state.paymentCards = payload
  }
}

const actions = {
  getLogCalls (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/log/calls'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getVoicemails (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/voicemail/list'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteVoicemail (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/voicemail/${uuid}/archive`

      axios.patch(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  toggleVoicemailReadStatus (_, { uuid, trigger }) {
    return new Promise((resolve, reject) => {
      const url = `/voicemail/${uuid}/${trigger}`

      axios.patch(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendViaEmailVoicemail (_, data) {
    return new Promise((resolve, reject) => {
      axios.post('/voicemail', data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  recordCall (_, { uuidActiveCall, action }) {
    // action should be 'start' or 'stop'
    return new Promise((resolve, reject) => {
      const url = `/call/${uuidActiveCall}/record/${action}`
      axios.post(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  markSpamCall (_, { uuidActiveCall, action }) {
    // action should be 'true' or 'false'
    return new Promise((resolve, reject) => {
      const url = `/call/${uuidActiveCall}/mark_spam/${action}`
      axios.post(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  markSpamAndHangUpCall (_, { uuidActiveCall }) {
    // action should be 'true' or 'false'
    return new Promise((resolve, reject) => {
      const url = `/call/${uuidActiveCall}/mark_spam_and_hangup`
      axios.post(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRecordings (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/media/list'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRecording (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/media/${uuid}`

      axios.get(url, { responseType: 'blob' })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  saveRecording (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/media'
      axios.post(url, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteRecording (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/media/${uuid}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setSelectedContactId ({ commit }, payload) {
    commit('setSelectedContactId', payload)
  },
  getVoicemailMailbox ({ commit }, payload) {
    commit('setVoicemailMailbox', payload.name)
  },
  deleteVoicemails ({ commit }, payload) {
    commit('removeVoicemails', payload.index)
  },

  // MEMBER ACTIONS
  getMembers ({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/account/users/account_all_users'

      axios.get(url, { params })
        .then(res => {
          const users = res.data.members
          commit('setAccountUsers', users)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getMember (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/account/user/${uuid}`

      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  createMember (_, { selectRole, formData }) {
    return new Promise((resolve, reject) => {
      const url = `/account/createUser/${selectRole}`
      axios.post(url, formData)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  editMember (_, list) {
    return new Promise((resolve, reject) => {
      const url = `/user/${list.form.uuid}`
      const data = {
        firstName: list.form.firstName,
        lastName: list.form.lastName
        // email: list.form.email
      }
      if (list.form.phone) data.userPhoneNumber = list.form.phone

      axios.patch(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deleteMember (_, id) {
    return new Promise((resolve, reject) => {
      const url = `/user/${id}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  disableMember (_, list) {
    return new Promise((resolve, reject) => {
      const url = `/user/${list.id}/active/${list.active}`

      axios.patch(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  // INVITATION ACTIONS
  inviteMember ({ commit }, list) {
    return new Promise((resolve, reject) => {
      const url = '/account/invite'
      const data = {
        firstName: list.form.firstName,
        lastName: list.form.lastName,
        email: list.form.email,
        role: list.form.value
      }

      axios.post(url, data)
        .then(res => {
          commit('setInvitationUuid', res.data.invitationUuid)
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }, // FOR CHECKING
  acceptInvitation ({ commit, dispatch }, { invitationUuid, data }) {
    return new Promise((resolve, reject) => {
      axios.post('/account/accept-invitation', data, { params: { invitationUuid } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }, // FOR CHECKING

  // CONTACTS ACTIONS
  getContacts (_, params = {}) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('currentUser'))
      const url = user.role === 'account_admin' ? '/account/contact/list' : '/user/contact/list'
      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getCallContact (_, number) {
    return new Promise((resolve, reject) => {
      const url = `/account/contact/list?number=${number}`

      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getContact (_, id) {
    return new Promise((resolve, reject) => {
      const url = `/contact/${id}`

      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getContactImage (_, { id, params }) {
    return new Promise((resolve, reject) => {
      const url = `/contact/${id}/image`

      axios.get(url, { ...params })
        .then(res => {
          // console.log(res, 'resss')
          if (res.status === 204) {
            resolve(null)
            return
          }
          const image = getBase64(res.data)

          resolve(image)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  setContactImage (_, { id, formData }) {
    return new Promise((resolve, reject) => {
      const url = `/contact/${id}/image`

      axios.post(url, formData)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getMemberImage (_, { id, params }) {
    return new Promise((resolve, reject) => {
      const url = `/account/user/${id}/image`

      axios.get(url, { ...params })
        .then(res => {
          // console.log(res, 'resss')
          if (res.status === 204) {
            resolve(null)
            return
          }
          const image = getBase64(res.data)

          resolve(image)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  setMemberImage (_, { userUuid, formData }) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('currentUser'))
      const url = `/upload/${user.accountUuid}/user/${userUuid}/image`

      axios.post(url, formData)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  createContact (_, list) {
    return new Promise((resolve, reject) => {
      const url = '/contact'

      axios.post(url, list)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  editContact (_, { number, payload }) {
    return new Promise((resolve, reject) => {
      const url = `/contact/${number}`

      axios.patch(url, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deleteContact (_, id) {
    return new Promise((resolve, reject) => {
      const url = `contact/${id}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  editContactFavorite (_, list) {
    return new Promise((resolve, reject) => {
      const url = `/contact/${list.id}`
      const data = { favourite: list.favorite }

      axios.patch(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  // USER ACTIONS
  getAllUsers ({ commit, dispatch }) {
    const authToken = auth.state.currentUser.token
    return new Promise((resolve, reject) => {
      axios({
        url: `${config.apiUrl}/company/${auth.state.currentUser.companyUuid}/members/user?orderType=asc`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken
        },
        method: 'GET'
      }).then(res => {
        resolve(res)
      })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }, // FOR CHECKING
  editUser ({ dispatch }, list) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('currentUser'))
      const url = user.role === 'account_admin' ? '/account' : '/user'

      axios.patch(url, list)
        .then(res => {
          const user = JSON.parse(localStorage.getItem('currentUser'))
          const newUser = {
            ...user,
            ...res.data.account
          }

          localStorage.setItem('currentUser', JSON.stringify(newUser))
          dispatch('setCurrentUser', newUser)
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  uploadUserAvatar ({ dispatch }, formData) {
    return new Promise((resolve, reject) => {
      const url = '/upload/user/image'

      axios.post(url, formData)
        .then(res => {
          dispatch('getAccountImage', { responseType: 'arraybuffer' })
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAccountImage ({ commit }, params) {
    return new Promise((resolve, reject) => {
      // const url = `/account/${uuid}/image/${link}`
      const url = '/user/image'

      axios.get(url, { ...params })
        .then(res => {
          if (res.status === 204) {
            commit('setUserImage', null)
            resolve(res)
            return
          }
          const image = getBase64(res.data)

          commit('setUserImage', image)

          resolve(res)
        })
        .catch(err => {
          commit('setUserImage', null)
          reject(err)
        })
    })
  },
  editNotice (_, notice) {
    return new Promise((resolve, reject) => {
      const url = '/account/notice'
      const data = notice

      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  // REST
  getPaymentsHistory (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/account/payment/list'
      const params = {
        offset: payload.offset,
        limit: payload.limit,
        endTime: payload.endDate,
        startTime: payload.startDate,
        orderBy: payload.orderBy,
        orderType: payload.orderType
      }

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStates (_, params) {
    return new Promise((resolve, reject) => {
      const url = '/public/list/state'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAvailableStates (_, params) {
    return new Promise((resolve, reject) => {
      const url = '/did/free_available_state'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAreaCodes (_, state) {
    return new Promise((resolve, reject) => {
      const url = `/public/list/npa-nxx/${state}`

      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getCallLogs (_, params) {
    return new Promise((resolve, reject) => {
      const url = '/log/activity'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  updateNotificationStatus (_, payload) {
    const authToken = auth.state.currentUser.token

    return new Promise((resolve, reject) => {
      axios({
        url: '/account/notifications/status',
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken
        },
        method: 'PATCH'
      }).then(res => {
        resolve(res)
      })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }, // FOR CHECKING
  getCallflows (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/callflow/list'
      const filter = {
        orderType: params?.orderType || 'desc',
        orderBy: params?.createdOn || 'createdOn',
        skip: params?.skip || '0',
        take: params?.take || '10',
        callFlowName: params?.filter || ''
      }

      axios.get(url, { params: filter })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  blockCallflow (_, { uuid, status }) {
    return new Promise((resolve, reject) => {
      const url = `/callflow/${uuid}/status`

      axios.patch(url, { status })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deleteCallflow (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/callflow/${uuid}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getExtension (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/extension/${uuid}`

      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  createExtension (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/extension'

      axios.post(url, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  editExtension (_, { uuid, payload }) {
    return new Promise((resolve, reject) => {
      const url = `/extension/${uuid}`

      axios.patch(url, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deleteExtension (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/extension/${uuid}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getMailboxes ({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('currentUser'))
      const url = user.role === 'account_admin' ? '/account/mailbox/list' : '/user/mailbox/list'
      axios.get(url, { params: params })
        .then(res => {
          if (!params.userUuid) commit('setMailboxes', res.data.mailboxList)
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getMailbox (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/mailbox/${uuid}`

      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addUserToMailbox (_, payload) {
    return new Promise((resolve, reject) => {
      const url = `/mailbox/${payload.mailboxUuid}/user/${payload.userUuid}`
      axios.put(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deleteUserFromMailbox (_, payload) {
    return new Promise((resolve, reject) => {
      const url = `/mailbox/${payload.mailboxUuid}/user/${payload.userUuid}`
      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  createMailbox (_, list) {
    return new Promise((resolve, reject) => {
      const url = '/mailbox'
      axios.post(url, list)
        .then(res => {
          // console.log(res, 'SSSSS')
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  editMailbox (_, list) {
    return new Promise((resolve, reject) => {
      const url = `/mailbox/${list.uuid}`
      axios.patch(url, list.payload)
        .then(res => {
          // console.log(res, 'SSSSS')
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deleteMailbox (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `mailbox/${uuid}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getInvoices (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/finance/invoices'
      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getStripeInvoices (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/payments/stripe_invoice/list'
      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getInvoice (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/finance/invoices/${uuid}`
      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getLogReport (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/log/report'
      axios.get(url, { params: params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getLiveCalls (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/log/call/live'
      axios.get(url, { params: params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getDashboardCall (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/log/dashboard/call'
      axios.get(url, { params: params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getDashboardSMS (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/log/dashboard/sms'
      axios.get(url, { params: params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getMessages (_, params) {
    return new Promise((resolve, reject) => {
      const url = 'log/sms'
      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  sendSms (_, { from, to, message }) {
    // console.log({ from, to, message })
    return new Promise((resolve, reject) => {
      const url = '/sms/send'
      axios.post(url, {
        from, to, message
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  setUnreadSms (_, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/log/sms/set_unread/${uuid}`
      axios.post(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getPaymentMethods ({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/payments/stripe/payment_card'
      axios.get(url, { params })
        .then(res => {
          commit('setPaymentCards', res.data.data)
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  postPaymentCard (_, data) {
    return new Promise((resolve, reject) => {
      const url = '/payments/stripe/payment_card'
      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  patchPaymentCard (_, { id, data }) {
    return new Promise((resolve, reject) => {
      const url = `/payments/stripe/payment_card/${id}`
      axios.patch(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  setDefaultPaymentCard (_, id) {
    return new Promise((resolve, reject) => {
      const url = `/payments/stripe/default_payment_card/${id}`
      axios.patch(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  setAutoChargeBalance (_, { enable, threshole }) {
    return new Promise((resolve, reject) => {
      const url = `/payments/auto_charge_balance/enable/${enable}`
      const query = {}
      if (threshole) query.params = { balance_threshole: threshole }
      axios.patch(url, {}, query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  deletePaymentCard (_, id) {
    return new Promise((resolve, reject) => {
      const url = `/payments/stripe/payment_card/${id}`
      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  addAccountBalance (_, data) {
    return new Promise((resolve, reject) => {
      const url = '/payments/account/balance'
      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  postSubdomain (_, prefix) {
    return new Promise((resolve, reject) => {
      const url = `/account/subdomain/${prefix}`
      axios.post(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAvailableCountries () {
    return new Promise((resolve, reject) => {
      const url = 'public/search/available-countries'
      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
