import Vue from 'vue'

const state = {
  chatView: {
    videoComponent: false,
    inviteComponent: false,
    sendComponent: false,
    messageComponent: false,
    recordComponent: false,
    informationComponent: false
  },
  keypad: {
    status: false
  },
  callStatus: {
    phone: '',
    status: false
  },
  callIncomingStatus: {
    phone: '',
    status: false
  },
  callIncomingBoxStatus: false,
  callAnswered: null,
  timerStatus: false,
  sipConnected: false,
  mute: true,
  hold: true,
  disabledMuteOutboundButton: true,
  disabledHoldOutboundButton: true,
  rightPopUpMyContacts: false,
  rightPopUpMyMailboxes: false,
  callbackRequested: false,
  callbackRequestedMailbox: false,
  playMessage: false,
  contactDetails: false,
  userInfo: {},
  playedMessage: {},
  selectedMailbox: {},
  selectedDidNumber: null,
  mobileSearch: false,
  calls: [
    // {
    //   type: 'IncomingRequest',
    //   state: '1231',
    //   id: 123132,
    //   mute: false,
    //   hold: false,
    //   time: false,
    //   firstname: '',
    //   lastname: '',
    //   location: '',
    //   active: true,
    //   checked: false,
    //   phoneNumber: 1201357746123
    // }
  ],
  chat: {},
  isMenuCollapsed: false,
  subscribeDialog: false,
  liveCalls: []
}

const getters = {
  selectedMailbox: s => s.selectedMailbox,
  selectedDidNumber: s => s.selectedDidNumber,
  mobileSearch: s => s.mobileSearch,
  spectrumLines () {
    return (length) => {
      return Array.from({ length }, () => (Math.floor(Math.random() * 5) + 1) * 6)
    }
  },
  isMenuCollapsed: s => s.isMenuCollapsed,
  subscribeDialog: s => s.subscribeDialog
}

const mutations = {
  setUserInfo (state, payload) {
    state.userInfo = payload
  },
  setPlayedMessage (state, payload) {
    state.playedMessage = payload
  },
  setChatView (state, payload) {
    state.chatView = payload
  },
  setChatVideoCall (state, payload) {
    state.chatView.videoComponent = payload
  },
  setChatInvite (state, payload) {
    state.chatView.inviteComponent = payload
  },
  setChatSend (state, payload) {
    state.chatView.sendComponent = payload
  },
  setChatMessage (state, payload) {
    state.chatView.messageComponent = payload
  },
  setChatRecord (state, payload) {
    state.chatView.recordComponent = payload
  },
  setChatInformation (state, payload) {
    state.chatView.informationComponent = payload
  },
  setKeypadStatus (state, payload) {
    state.keypad.status = payload
  },
  setCallModalStatus (state, payload) {
    state.callStatus = payload
  },
  setCallIncomingModalStatus (state, payload) {
    Vue.set(state, 'callIncomingStatus', payload)
  },
  setCallIncomingBoxStatus (state, payload) {
    state.callIncomingBoxStatus = payload
  },
  setMuteStatus (state, payload) {
    state.mute = payload
  },
  setHoldStatus (state, payload) {
    state.hold = payload
  },
  setStartCallTimer (state, payload) {
    state.timerStatus = payload
  },
  setCalAnsweredStatus (state, payload) {
    state.callAnswered = payload
  },
  setRightPopUpMyContacts (state, payload) {
    state.rightPopUpMyContacts = payload
  },
  setRightPopUpMyMailboxes (state, payload) {
    state.rightPopUpMyMailboxes = payload
  },
  setCallbackRequested (state, payload) {
    state.callbackRequested = payload
  },
  setCallbackRequestedMailbox (state, payload) {
    state.callbackRequestedMailbox = payload
  },
  setPlayMessage (state, payload) {
    state.playMessage = payload
  },
  setContactDetails (state, payload) {
    state.contactDetails = payload
  },
  setSelectedMailbox (state, payload) {
    state.selectedMailbox = payload
  },
  setSelectedDidNumber (state, payload) {
    state.selectedDidNumber = payload
  },
  addCall (state, payload) {
    state.calls.push(payload)
  },
  removeCall (state, id) {
    const index = state.calls.findIndex(item => item.id === id)
    if (index !== -1) {
      state.calls.splice(index, 1)
    }
  },
  muteCall (state, payload) {
    const index = state.calls.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      Vue.set(state.calls[index], 'mute', payload.mute)
    }
    // state.calls[index].mute = payload.mute
  },
  holdCall (state, payload) {
    const index = state.calls.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      Vue.set(state.calls[index], 'hold', payload.hold)
    }
    // state.calls[index].hold = payload.hold
  },
  disableMuteOutboundButton (state, payload) {
    state.disabledMuteOutboundButton = payload
  },
  disableHoldOutboundButton (state, payload) {
    state.disabledHoldOutboundButton = payload
  },
  updateCall (state, payload) {
    const index = state.calls.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      Vue.set(state.calls, index, {
        ...state.calls[index],
        ...payload
      })
    }
  },
  setSipConnected (state, payload) {
    state.sipConnected = payload
  },
  setMobileSearch (state, payload) {
    state.mobileSearch = payload
  },
  setNewSms (state, payload) {
    const _payload = payload.data
    const _type = payload.type
    if (!_payload.text) {
      return
    }
    const typeOrDirection = _payload.type ? 'type' : 'direction'
    const type = _payload[typeOrDirection] === 'in' ? 'ani' : 'dnis'
    const number = _type === 'any' ? _payload[type] : _payload.to
    const numbers = Object.keys(state.chat)
    const n = numbers.find(n => +n === +number)
    if (n) {
      if (state.chat[n].find(m => m.uuid === _payload.uuid)) {
        return
      }
      Vue.set(state.chat, n, [...state.chat[n], _payload])
    } else {
      Vue.set(state.chat, number, [_payload])
    }
  },
  setMenuCollapsed (state, payload) {
    state.isMenuCollapsed = payload
  },
  setSubscribeDialog (state, payload) {
    state.subscribeDialog = payload
  },
  setUpdateLiveCalls (state, payload) {
    if (payload.type !== 'CallSIP') {
      return
    }

    const indexOfLiveCall = state.liveCalls.findIndex(el => {
      return el.uuid === payload.uuid
    })

    const allowedStates = ['init', 'ringing', 'life', 'hold']

    if (indexOfLiveCall !== -1) {
      if (!allowedStates.includes(payload.state)) {
        state.liveCalls.splice(indexOfLiveCall, 1)
        return
      }
      Vue.set(state.liveCalls, indexOfLiveCall, payload)
    } else if (allowedStates.includes(payload.state)) {
      state.liveCalls.unshift(payload)
    }
  },
  resetLiveCalls (state, payload = []) {
    Vue.set(state, 'liveCalls', payload)
  }
}

const actions = {
  setPlayedMessage ({ commit }, payload) {
    commit('setPlayedMessage', payload)
  },
  chatVideoCall ({ commit }, payload) {
    commit('setChatVideoCall', payload)
  },
  chatInvite ({ commit }, payload) {
    commit('setChatInvite', payload)
  },
  chatSend ({ commit }, payload) {
    commit('setChatSend', payload)
  },
  chatMessage ({ commit }, payload) {
    commit('setChatMessage', payload)
  },
  playMessage ({ commit }, payload) {
    commit('setPlayMessage', payload)
  },
  chatRecord ({ commit }, payload) {
    commit('setChatRecord', payload)
  },
  chatInformation ({ commit }, payload) {
    commit('setChatInformation', payload)
  },
  keypadStatus ({ commit }, payload) {
    commit('setKeypadStatus', payload)
  },
  callModalStatus ({ commit }, payload) {
    commit('setCallModalStatus', payload)
  },
  callIncomingModalStatus ({ commit }, payload) {
    commit('setCallIncomingModalStatus', payload)
  },
  callIncomingBoxStatus ({ commit }, payload) {
    commit('setCallIncomingBoxStatus', payload)
  },
  mute ({ commit }, payload) {
    commit('setMuteStatus', payload)
  },
  hold ({ commit }, payload) {
    commit('setHoldStatus', payload)
  },
  startTimerEvent ({ commit }, payload) {
    commit('setStartCallTimer', payload)
  },
  callAnswered ({ commit }, payload) {
    commit('setCalAnsweredStatus', payload)
  },
  RightPopUpMyContacts ({ commit }, payload) {
    commit('setRightPopUpMyContacts', payload)
    if (!payload) {
      commit('setSelectedMailbox', {})
      commit('setSelectedDidNumber', null)
    }
  },
  RightPopUpMyMailboxes ({ commit }, payload) {
    commit('setRightPopUpMyMailboxes', payload)
  },
  CallbackRequested ({ commit }, payload) {
    commit('setCallbackRequested', payload)
  },
  CallbackRequestedMailbox ({ commit }, payload) {
    commit('setCallbackRequestedMailbox', payload)
  },
  PlayMessage ({ commit }, payload) {
    commit('setPlayMessage', payload)
  },
  ContactDetails ({ commit }, payload) {
    commit('setContactDetails', payload)
  },
  userInfo ({ commit }, payload) {
    commit('setUserInfo', payload)
  },
  setSelectedMailbox ({ commit }, payload) {
    commit('setSelectedMailbox', payload)
  },
  setSelectedDidNumber ({ commit }, payload) {
    commit('setSelectedDidNumber', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
